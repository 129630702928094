.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



/* //Side Scroll bar */

  /* //::-webkit-scrollbar */


  /* width */
  ::-webkit-scrollbar {
    width: 8px !important;
    height: 8px;
  
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey !important; 
    border-radius: 8px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #0e7fe1 !important; 
    border-radius: 8px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b30000 !important; 
  }
  

  ::-webkit-scrollbar-thumb {
    width: 8px !important;
    /* height: 100px; */
    overflow-x: scroll !important;
    /* scrollbar-width: thin; */
    /* background: #673f3f !important;  */


  }
